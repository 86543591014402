import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { AnimatePresence, motion } from "framer-motion";

import { grid, spacing, screen, fontSize } from "../../styles/mixins";
import { colours } from "../../styles/colours";
import { graphql, Link, useStaticQuery } from "gatsby";
import { fontSurt, h3, meta } from "../../styles/typography";
import { useLocation } from "@reach/router";
import { withPrefix } from "gatsby-link";

const Container = styled(motion.nav)`
	position: fixed;
	z-index: 99;
	background: ${colours.bgDarkGrey};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	overflow-y: auto;
	display: grid;
	align-items: stretch;
	pointer-events: auto;
`;

const PaddingWrapper = styled.div`
	${spacing("paddingTop", "m")};
	${spacing("paddingBottom", "m")};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: stretch;
`;

const HeaderWrapper = styled.div`
	${grid};
	width: 100%;
	${spacing("marginBottom", "l")};
`;

const BodyWrapper = styled.div`
	${grid};
	width: 100%;
	align-items: center;
	flex-grow: 1;

	> ul {
		grid-column: 6 / -6;
		@media ${screen("md")} {
			grid-column: 9 / -9;
		}
	}
`;

const MenuPart = styled(motion.li)`
	${spacing("marginBottom", "l")};
	${spacing("marginTop", "s")};
	${spacing("paddingBottom", "l")};
	text-align: left;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	${(props) =>
		!props.link &&
		css`
			> * {
				opacity: 0.3;
			}
		`};

	&:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}

	ul {
		${spacing("marginTop", "l")};

		@media ${screen("md")} {
			${spacing("marginTop", "m")};
		}

		li {
			&:not(:last-child) {
				${spacing("marginBottom", "m")};

				@media ${screen("md")} {
					${spacing("marginBottom", "xs")};
				}
			}

			a {
				&:hover {
					color: ${colours.highlight};
				}
			}
		}
	}
`;

const ChapterNumber = styled.span`
	text-transform: lowercase;
`;

const TitleBase = () =>
	css`
		display: block;
		${fontSurt}
		${fontSize(5)};
	`;

const Title = styled.span`
	${TitleBase};
`;

const TitleLink = styled(Link)`
	${TitleBase};

	&:hover {
		color: ${colours.highlight};
	}
`;

const Number = styled.span`
	display: block;
	grid-area: number;
	text-transform: uppercase;
	${spacing("marginBottom", "s")}
	${meta};
`;

const SubLink = styled(Link)`
	transition: color 400ms ease;

	&:hover {
		color: ${colours.highlight};
	}
`;

const FooterWrapper = styled.div`
	${grid};
	width: 100%;
	${spacing("marginTop", "xl")};
	${meta};

	@media ${screen("md")} {
		${spacing("marginTop", "l")};
	}
`;

const FooterLinks = styled.div`
	grid-column: 3 / -3;
	${spacing("marginBottom", "l")};
	transition: color 400ms ease;

	@media ${screen("md")} {
		grid-column: 3 / 38;
		margin-bottom: 0;
	}

	a {
		display: block;
		${spacing("marginBottom", "s")};
		transition: color 400ms ease;

		@media ${screen("md")} {
			display: inline-block;
			${spacing("marginRight", "xl")};
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}

		&:hover {
			color: ${colours.highlight};
		}
	}
`;

const Copyright = styled.div`
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: -10 / -3;
		justify-content: end;
		text-align: right;
	}
`;

let panelAnimation = {
	enter: {
		x: "0%",
		transition: {
			type: "tween",
			ease: [0.5, 1, 0.89, 1],
			duration: 0.5,
			when: "beforeChildren",
		},
	},
	exit: {
		x: "100%",
		transition: {
			type: "tween",
			ease: [0.5, 1, 0.89, 1],
			duration: 0.5,
			when: "afterChildren",
		},
	},
};

const staggerTiming = 0.1;

const primaryListAnimation = {
	enter: {
		opacity: 1,
		transition: {
			delay: 0.3,
		},
	},
	exit: { opacity: 0 },
};

const primaryItemAnimation = {
	enter: (i) => ({
		opacity: 1,
		transition: {
			delay: i * staggerTiming,
		},
	}),
	exit: { opacity: 0 },
};

const secondaryItemAnimation = {
	enter: (i) => ({
		opacity: 1,
		transition: {
			delay: i * staggerTiming,
		},
	}),
	exit: { opacity: 0 },
};

export default function Navigation({ open, handleToggle }) {
	const ref = useRef();
	let location = useLocation();
	const isOpen = useRef(false);

	const {
		allAct1Yaml: { nodes: act1MenuItems },
		allAct2Yaml: { nodes: act2MenuItems },
	} = useStaticQuery(graphql`
		query NavQuery {
			allAct1Yaml(sort: { fields: order, order: ASC }) {
				nodes {
					menuTitle
					keyId
				}
			}
			allAct2Yaml(sort: { fields: order, order: ASC }) {
				nodes {
					menuTitle: title
					keyId
				}
			}
		}
	`);

	const menuItems = [
		{
			title: "Introduction",
			link: "/",
		},
		{
			subtitle: "Part 1",
			title: "A Promise Broken",
			link: "/a-promise-broken/",
			menuItems: act1MenuItems,
		},
		{
			subtitle: "Part 2",
			title: "Economic Opportunities for our Avatars",
			link: "/economic-opportunities-for-our-avatars/",
			menuItems: act2MenuItems,
		},
		{
			subtitle: "Part 3",
			title: "Forecasting Fragility",
			link: "/forecasting-fragility/",
		},
	];

	useEffect(() => {
		const scrollEl = ref.current;

		if (open) {
			isOpen.current = true;
			disablePageScroll(scrollEl);
		} else if (isOpen.current) {
			enablePageScroll(scrollEl);
			isOpen.current = false;
		}

		return () => {
			if (isOpen.current) {
				enablePageScroll(scrollEl);
			}
		};
	}, [open, isOpen]);

	let animationCounter = 0;

	return (
		<AnimatePresence>
			{open && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					css={{ position: "relative", zIndex: 99 }}>
					<Container ref={ref} variants={panelAnimation}>
						<PaddingWrapper>
							<HeaderWrapper />
							<BodyWrapper>
								<motion.ul
									variants={primaryListAnimation}
									initial="exit"
									animate="enter">
									{menuItems.map((item, i) => {
										animationCounter++;
										return (
											<MenuPart
												key={i}
												variants={primaryItemAnimation}
												custom={animationCounter - 1}
												link={item.link}>
												<Number>{item.subtitle}</Number>
												{item.link ? (
													<TitleLink to={item.link} onClick={handleToggle}>
														{item.title}
													</TitleLink>
												) : (
													<Title>{item.title}</Title>
												)}
												{item.menuItems &&
												withPrefix(item.link) === location.pathname ? (
													<ul>
														{item.menuItems.map((subItem, i) => {
															animationCounter++;
															return (
																<motion.li
																	key={subItem.keyId}
																	variants={secondaryItemAnimation}
																	custom={animationCounter - 1}>
																	<SubLink
																		to={`${item.link}#${subItem.keyId}`}
																		onClick={handleToggle}>
																		<ChapterNumber>0{i + 1}.</ChapterNumber>{" "}
																		{subItem.menuTitle}
																	</SubLink>
																</motion.li>
															);
														})}
													</ul>
												) : null}
											</MenuPart>
										);
									})}
								</motion.ul>
							</BodyWrapper>
							<FooterWrapper>
								<FooterLinks>
									<a
										href="https://atelier.net"
										target="_blank"
										rel="noreferrer">
										L’Atelier Corporate Website
									</a>
								</FooterLinks>
								<Copyright>
									© L’Atelier BNP Paribas {new Date().getFullYear()}
								</Copyright>
							</FooterWrapper>
						</PaddingWrapper>
					</Container>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
